import React, { PureComponent } from 'react'
import { node, object } from 'prop-types'
import {
  TransitionGroup,
  Transition as ReactTransition
} from 'react-transition-group'

const timeout = 50
const getTransitionStyles = {
  entering: {
    position: `absolute`,
    opacity: 0
  },
  entered: {
    transition: `opacity ${timeout}ms ease-in-out`,
    opacity: 1
  },
  exiting: {
    transition: `opacity ${timeout}ms ease-in-out`,
    opacity: 0
  }
}

class Transition extends PureComponent {
  render () {
    const { children, location } = this.props

    return (
      <TransitionGroup>
        <ReactTransition
          key={location.pathname}
          timeout={{
            enter: timeout,
            exit: timeout
          }}
        >
          {status => (
            <div
              style={{
                ...getTransitionStyles[status]
              }}
            >
              {children}
            </div>
          )}
        </ReactTransition>
      </TransitionGroup>
    )
  }
}

Transition.propTypes = {
  children: node,
  location: object
}

export default Transition
